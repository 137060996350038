import React from "react";

function Footer() {
  return (
    <footer>
      <p>built with react by eric lee © 2024. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
